<template>
  <v-card>
    <v-card-title class="headline justify-center">
      Academic Information
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <academics-form :player="model" :disabled="!editing"></academics-form>
    </v-card-text>
    <v-divider v-if="false"></v-divider>
    <v-card-actions v-if="false">
      <v-row dense>
        <v-col class="text-right" cols="12" >
          <v-fab-transition><!-- CANCEL -->
            <v-btn
              color="error white--text"
              fab small
              @click.stop="cancel"
              v-if="editing"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition><!-- EDIT/SAVE -->
            <v-btn
              v-if="fab"
              :color="fab.color"
              fab small
              :key="fab.key"
              @click.stop="fab.click"
              :loading="fab.loading"
              :disabled="fab.disabled"
            >
              <v-icon>{{fab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-fab-transition><!-- CANCEL -->
      <v-btn
        color="success white--text"
        fab
        :small="smallFab"
        fixed bottom right
        @click.stop="onSaveClick"
        :loading="loading"
        :disabled="!saveable"
        v-if="editing"
        :style="smallFab ? 'margin-right: 45px;' : 'margin-right: 65px;'"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition><!-- EDIT/SAVE -->
      <v-btn
        v-if="fab"
        :color="fab.color"
        fab
        :small="smallFab"
        :key="fab.key"
        @click.stop="fab.click"
        :loading="fab.loading"
        :disabled="fab.disabled"
        fixed bottom right
      >
        <v-icon>{{fab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-card>
</template>

<script>
import AcademicsForm from '@/components/Forms/AcademicsForm'

export default {
  props: ['player', 'active'],
  data () {
    return {
      editing: false,
      editable: null,
      loading: false
    }
  },
  computed: {
    model () {
      return this.editing ? this.editable : this.dataOg
    },
    smallFab () {
      return this.$vuetify.breakpoint.smAndDown
    },
    fab () {
      return this.editing ? {
        key: 'cancel',
        color: 'error white--text',
        icon: 'fas fa-times',
        click: this.cancel,
        loading: this.loading
      } : {
        key: 'edit',
        color: 'color3 color3Text--text',
        icon: 'fas fa-pencil',
        click: this.edit,
        loading: false,
        disabled: this.loading
      }
    },
    dataOg () {
      return this.player.academicInfoDto
    },
    dirty () {
      return this.editable && (JSON.stringify(this.editable) !== JSON.stringify(this.dataOg))
    },
    saveable () {
      return this.dirty
    },
    dto () {
      if (!this.editable) return null
      const a = {
        id: this.player.id
      }
      if (this.player.highSchool !== this.editable.highSchool) a.highSchool = this.editable.highSchool
      if (this.player.gradYear !== this.editable.gradYear) a.gradYear = this.editable.gradYear
      const j = Object.assign({}, this.player.jProps)
      j.academics = this.editable.academics
      if (JSON.stringify(j) !== this.player.jsonProps) {
        a.jsonProps = JSON.stringify(j)
      }

      return a
    }
  },
  methods: {
    edit () {
      this.editable = JSON.parse(JSON.stringify(this.dataOg))
      this.editing = true
    },
    cancel () {
      this.editing = false
      this.editable = null
    },
    onSaveClick () {
      this.loading = true
      this.$VBL.player.patch(this.dto)
        .then(r => {
          this.onSaved(this.dto)
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    onSaved (result) {
      result && this.player.update(result)
      this.editing = false
    }
  },
  watch: {
    active: function (val) {
      if (!val) {
        this.cancel()
      }
    }
  },
  components: {
    AcademicsForm
  }
}
</script>
