<template>
  <v-container fluid class="pt-0">
    <loading-bar :value="isLoading"></loading-bar>
    <v-row dense> <!-- College/University Preferences -->
      <v-col cols="12">
        <h3>College/University Preferences</h3>
      </v-col>
      <v-col cols="12"> <!-- model.majors -->
        <v-combobox
          label="Desired Course(s) of Study"
          :items="majors"
          v-model="model.majors"
          :search-input.sync="search"
          chips deletable-chips
          multiple
          color="color3"
          item-color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="Select all areas that interest you"
          :persistent-hint="!disabled"
          attach
        >
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="6"><!-- model.regions -->
        <v-select
          :items="['West', 'Mountain', 'Southwest', 'Midwest', 'East', 'Southeast', 'Northwest', 'Midsouth', 'Northeast'].sort()"
          v-model="model.regions"
          multiple
          chips deletable-chips
          label="Preferred Region(s)"
          color="color3"
          item-color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="What region(s) are you looking in for schools? (select all that apply)"
          :persistent-hint="!disabled"
          select-all
        ></v-select>
      </v-col>
      <v-col cols="12" md="6"><!-- model.divisions -->
        <v-select
          :items="['CCCAA', 'NAIA', 'NAIA DI', 'NAIA DII', 'NCAA DI', 'NCAA DII', 'NCAA DIII', 'NJCAA'].sort()"
          v-model="model.divisions"
          multiple
          chips deletable-chips
          label="Preferred Division(s)"
          color="color3"
          item-color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="What division(s) are you willing to play in? (select all that apply)"
          :persistent-hint="!disabled"
          select-all
        ></v-select>
      </v-col>
      <v-col cols="12" md="6"><!-- model.selectivity -->
        <v-select
          :items="['Most Selective', 'More Selective', 'Selective', 'Less Selective', 'Least Selective']"
          v-model="model.selectivity"
          multiple
          chips deletable-chips
          label="Preferred Admissions Selectivity"
          color="color3"
          item-color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="Do you prefer schools that are harder to get in to or easier? (select all that apply)"
          :persistent-hint="!disabled"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6"><!-- model.enrollment -->
        <v-select
          :items="['Over 20,000', '10,000 - 20,000', '3,000 - 10,000', 'Under 3,000']"
          v-model="model.enrollment"
          multiple
          chips deletable-chips
          label="Undergraduate Enrollment"
          color="color3"
          item-color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="What size school do you prefer? (select all that apply)"
          :persistent-hint="!disabled"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="order-xs1"   > <!-- NCAA Eligibility -->
      <v-col cols="12">
        <h3>NCAA Eligibility</h3>
      </v-col>
      <v-col cols="12" sm="6"><!-- haveNcaa -->
        <v-radio-group
          v-model="haveNcaa"
          row
          class="align-start"
          hide-details
          :disabled="disabled"
        >
          <template slot="label" class="mr-4">
            Do you have an NCAA Eligibility Number?
          </template>
          <v-radio
            class="ml-4"
            color="color3"
            label="Yes"
            :value="true"
          ></v-radio>
          <v-radio
            color="color3"
            label="No"
            :value="false"
          ></v-radio>
        </v-radio-group>
        <div class="subheading" v-if="false">Go to http://eligibilitycenter.org/ for more information</div>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.ncaaEligibilityNumber -->
        <v-text-field
          :label="'NCAA Eligibility #'"
          v-model="model.ncaaEligibilityNumber"
          color="color3"
          :disabled="disabled || !haveNcaa"
          :hide-details="disabled"
          hint="Enter your NCAA Eligibility #"
          :persistent-hint="!disabled"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense> <!-- Current School -->
      <v-col cols="12">
        <h3>High School</h3>
      </v-col>
      <v-col cols="12" sm="8"><!-- player.highSchool -->
        <v-text-field
          :label="`High School Name${v && v.player.highSchool.hasOwnProperty('required') ? '*' : ''}`"
          v-model="player.highSchool"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          @blur="v && v.player.highSchool.$touch()"
          :rules="v && v.player.highSchool.hasOwnProperty('required') && v.player.highSchool.$dirty ? [
            () => v.player.highSchool.required || 'School name is required'
          ] : []"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4"><!-- player.gradYear -->
        <v-autocomplete
          :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => { return i + new Date().getFullYear() })"
          :label="v && v.player.gradYear.hasOwnProperty('required') ? 'HS Graduation Year*' : 'HS Graduation Year'"
          v-model="player.gradYear"
          @blur="v && v.player.gradYear.$touch()"
          :rules="v && v.player.gradYear.hasOwnProperty('required') && v.player.gradYear.$dirty ? [
            () => v.player.gradYear.required || 'Please select a graduation year'
          ] : []"
          clearable
          :hint="`What year will you graduate?`"
          :persistent-hint="!disabled"
          color="color3"
          item-color="color3"
          menu-props="auto"
          autocomplete="false"
          type="search"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.hsCity -->
        <v-text-field
          :label="'High School City'"
          v-model="model.hsCity"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.hsState -->
        <v-autocomplete
          :label="'High School State'"
          :items="states"
          item-text="abbreviation"
          item-value="abbreviation"
          v-model="model.hsCity"
          color="color3"
          item-color="color3"
          autocomplete="off-yo"
          type="search"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.gpa -->
        <v-text-field
          :label="`GPA${v && v.player.academics.gpa.hasOwnProperty('required') ? '*' : ''}`"
          v-model="model.gpa"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="What is your current GPA (grade point average)?"
          :persistent-hint="!disabled"
          type="number"
          @blur="() => { model.gpa = parseFloat(model.gpa).toFixed(1); v && v.player.academics.gpa.$touch() }"
          :rules="v && v.player.academics.gpa.hasOwnProperty('required') && v.player.academics.gpa.$dirty ? [
            () => v.player.academics.gpa.required || 'A GPA is required'
          ] : []"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.gpaScale -->
        <v-text-field
          :label="'GPA Scale'"
          v-model="model.gpaScale"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="What is your schools GPA scale?"
          :persistent-hint="!disabled"
          type="number"
          @blur="() => { model.gpaScale = parseFloat(model.gpaScale).toFixed(1) }"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.classSize -->
        <v-text-field
          :label="'Class Size'"
          v-model="model.classSize"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          type="number"
          hint="How many students are in your graduating class?"
          :persistent-hint="!disabled"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6"><!-- model.classRank -->
        <v-text-field
          :label="'Class Rank'"
          v-model="model.classRank"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          type="number"
          hint="What is your current class rank (usually found on your latest report card or transcript)?"
          :persistent-hint="!disabled"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense><!-- Transcript -->
      <v-col cols="12">
        <h3>Transcript</h3>
      </v-col>
      <v-col cols="12"><!-- model.transcript -->
        <v-window v-model="tWindow" touchless>
          <v-window-item :value="0">
            <v-text-field
              :label="'Transcript URL'"
              v-model="model.transcriptUrl"
              color="color3"
              :disabled="disabled"
              :hide-details="disabled"
              hint="Enter the full URL where your transcript can be found"
              :persistent-hint="!disabled"
            ></v-text-field>
            <v-btn color="color3" class="xsmall" text @click.stop="tWindow = 1" v-if="false">I don't have a URL</v-btn>
          </v-window-item>
          <v-window-item :value="1">
            <v-col cols="12" class="subheading">
              {{ currentFile ? `You have selected ${currentFile.name} to upload` : 'To upload your transcript:'}}
              <v-btn
                :class="currentFile ? 'color3Text color3--text' : 'color3 color3Text--text'"
                @click="onPickFile"
                :disabled="busy"
              >{{`Choose a ${currentFile ? 'new ' : ''}File`}}</v-btn>
              <v-btn color="red" class="xsmall" text @click.stop="currentFile = null" v-if="currentFile">clear</v-btn>
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                @change="onFilePicked">
            </v-col>
            <v-btn color="color3" class="xsmall" text @click.stop="tWindow = 0">I don't have a file</v-btn>
          </v-window-item>

        </v-window>
      </v-col>
    </v-row>
    <v-row dense><!-- Test Scores -->
      <v-col cols="12">
        <h3>Test Scores</h3>
      </v-col>
      <v-col cols="12" sm="6" md="4"><!-- model.satMath -->
        <v-text-field
          :label="'SAT - Math'"
          v-model="model.satMath"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="Enter your score on the Math Section"
          :persistent-hint="!disabled"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4"><!-- model.satEbrw -->
        <v-text-field
          :label="'SAT - EBRW'"
          v-model="model.satEbrw"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="Enter your score on the Evidence-Based Reading and Writing Section"
          :persistent-hint="!disabled"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4"><!-- model.act -->
        <v-text-field
          :label="'ACT Composite'"
          v-model="model.act"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
          hint="Enter your ACT composite score"
          :persistent-hint="!disabled"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense> <!-- Additional Information -->
      <v-col cols="12">
        <h3>Additional Information</h3>
      </v-col>
      <v-col cols="12" md="6"><!-- model.honors -->
        <v-textarea
          label="Academic Honors & Awards"
          v-model="model.honors"
          hint="List any academic honors and awards you have received"
          :persistent-hint="!disabled"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6" v-if="false"><!-- model.study -->
        <v-textarea
          label="Desired Course(s) of Study"
          v-model="model.study"
          hint="Tell us what areas of study you are interested in"
          :persistent-hint="!disabled"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6"><!-- model.extracurricular -->
        <v-textarea
          label="Extracurricular Activities"
          v-model="model.extracurricular"
          hint="What other extracurricular activities (besides volleyball) do you participate in"
          :persistent-hint="!disabled"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6"><!-- model.hobbies -->
        <v-textarea
          label="Hobbies"
          v-model="model.hobbies"
          hint="What other hobbies (besides volleyball) do you enjoy"
          :persistent-hint="!disabled"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6"><!-- model.service -->
        <v-textarea
          label="Community Service"
          v-model="model.service"
          hint="Tell us about any community service you are doing or have done"
          :persistent-hint="!disabled"
          color="color3"
          :disabled="disabled"
          :hide-details="disabled"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['player', 'disabled', 'v'],
  data () {
    return {
      tWindow: 0,
      busy: false,
      currentFile: null,
      majors: [],
      states: [],
      loading: new Set(),
      haveNcaa: false,
      search: null
    }
  },
  computed: {
    isLoading () {
      return !!this.loading.size
    },
    model () {
      return this.player.academics
    }
  },
  methods: {
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) return
      this.busy = true
      this.currentFile = file
      this.busy = false
    },
    getMajors () {
      if (this.majors.length) return
      this.loading.add('m')
      this.$http.get(`${window.location.origin}/static/json/Majors.json`)
        .then(r => {
          this.majors = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading.delete('m') })
    },
    getStates () {
      if (this.states.length) return
      this.loading.add('s')
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading.delete('s') })
    }
  },
  watch: {
    disabled: function () {
      this.getMajors()
      this.getStates()
    }
  },
  mounted () {
    this.haveNcaa = this.model && !!this.model.ncaaEligibilityNumber
  }
}
</script>
